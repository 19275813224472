
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cxn-tournament-evaluator-primary: mat-palette($mat-indigo);
$cxn-tournament-evaluator-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cxn-tournament-evaluator-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cxn-tournament-evaluator-theme: mat-light-theme((
  color: (
    primary: $cxn-tournament-evaluator-primary,
    accent: $cxn-tournament-evaluator-accent,
    warn: $cxn-tournament-evaluator-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cxn-tournament-evaluator-theme);

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: var(--color-bg-high);
}

html {
  font-size: 16px;

  --bg: #121212;
  --bg-layer1: rgb(255, 255, 255, 0.05);
  --bg-layer2: rgb(255, 255, 255, 0.07);
  --bg-layer3: rgb(255, 255, 255, 0.08);
  --bg-layer4: rgb(255, 255, 255, 0.09);
  --bg-layer5: rgb(255, 255, 255, 0.11);
  --bg-layer6: rgb(255, 255, 255, 0.12);
  --bg-layer7: rgb(255, 255, 255, 0.14);
  --bg-layer8: rgb(255, 255, 255, 0.15);
  --bg-layer9: rgb(255, 255, 255, 0.16);
  --bg-error: #CF6679;

  --color-bg: #FFFFFF;
  --color-bg-high: rgb(255, 255, 255, 0.87);
  --color-bg-medium: rgb(255, 255, 255, 0.60);
  --color-bg-disabled: rgb(255, 255, 255, 0.38);
  --color-surface: #FFFFFF;
  --color-oncolor: #000000;

  //--primary: #5ec3d9;
  //--primary2: #118ab2;
  //--secondary: #f8aa90;
  //--secondary2: #d94c1b;

  --primary: #ffd166;
  --primary2: #f99e37;
  --secondary: #81b5ff;
  --secondary2: #5e73db;

}

body {
  background-color: var(--bg);
  color: var(--color);
  height: 100%;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  width: 100vw;
}

body::-webkit-scrollbar-track {
  background: var(--bg-accent);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar {
  background: var(--bg-accent);
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.skeleton {
  $from: rgb(255, 255, 255, 0.16);
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, var(--bg-layer4) 0%, var(--bg-layer8) 50%, var(--bg-layer4) 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0;
    }
  }
}

.noSelect {
  cursor: default;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hidden {
  display: none !Important;
}

.vanish {
  visibility: hidden !Important;
}

.mention {
  &-user {
    color: var(--secondary);
  }

  &-rank {
    color: var(--secondary2);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
